<template>
  <v-container fluid>
    <v-row align="center" v-if="viaggioTreno.idConvoglioPianificato" class="my-2 py-2">
      <v-col cols="6" md="3">
        <v-container fluid class="ma-0 pa-0">
          <v-row align="center">
            <v-col cols="12" class="text-h6 font-weight-light mb-0 pb-0">{{ tLabel("Convoglio associato") }}:</v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" class="text-h6 font-weight-medium">{{ viaggioTreno.nomeConvoglioPianificato }}</v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="6" md="3">
        <v-btn @click="unjoinConvoy" color="primary"><v-icon class="mr-2">mdi-link-off</v-icon>Disassocia</v-btn>
      </v-col>
    </v-row>

    <v-row no-gutters align="center" v-else>
      <!-- <v-col cols="6" md="2">
        <span class="text-h6 font-weight-regular">{{ tLabel("Modalità di composizione") }}:</span>
      </v-col> -->
      <v-col cols="6" md="3">
        <v-btn-toggle color="primary">
          <v-btn :color="selectionType === 'CONVOY_NAME' ? 'primary' : 'grey lighten-1'" @click="selectionType = 'CONVOY_NAME'">
            <span :class="selectionType === 'CONVOY_NAME' ? 'white--text' : null">{{ tLabel("Convoglio") }}</span>
          </v-btn>
          <v-btn :color="selectionType === 'WAGON_TYPE' ? 'primary' : 'grey lighten-1'" @click="selectionType = 'WAGON_TYPE'">
            <span :class="selectionType === 'WAGON_TYPE' ? 'white--text' : null">{{ tLabel("Carri fittizi") }}</span>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-row v-if="!viaggioTreno.idConvoglioPianificato && selectionType === 'CONVOY_NAME'">
      <v-col cols="12">
        <v-container fluid class="ma-0 pa-0">
          <v-row>
            <v-col v-if="selectionType === 'CONVOY_NAME'" cols="6" md="3">
              <v-select
                v-if="!tripPlanned"
                clearable
                v-model="selectedConvoyName"
                :items="convoyListNames"
                label="Convoglio da associare"
                item-text="nome"
                @change="changeConvoy"
              />
            </v-col>
          </v-row>
          <v-row v-if="selectedConvoy">
            <v-col cols="12">
              <div v-if="!selectedConvoy.listCarri || !selectedConvoy.listCarri.length" class="wagonGroupPanel">
                <span class="text-body-1 font-weight-regular mr-2">Nessun carro associato al convoglio</span>
                <v-btn @click="joinEmptyConvoy" color="primary" v-if="!viaggioTreno.idConvoglioPianificato"><v-icon class="mr-2">mdi-link</v-icon>Associa convoglio </v-btn>
              </div>
              <div v-else class="wagon-group-panel-container">
                <div class="wagon-group-check">
                  <v-checkbox label="Seleziona tutti" v-model="selectedAllConvoyWagonList" style="margin: 0px; padding: 0px; float: left" dense @change="selectAllWagons" />
                </div>
                <div v-for="carroConvoglio in selectedConvoy.listCarri" :key="carroConvoglio.id" class="wagon-group-item">
                  <v-card :class="`wagon-card ${carroConvoglio.joined ? 'joined-wagon' : 'not-joined-wagon'}`">
                    <span class="wagon-header">
                      {{ getCarroLicensePlate(carroConvoglio) }}
                      <v-checkbox v-if="!carroConvoglio.joined" v-model="selectedConvoyWagonList" :value="carroConvoglio" style="margin: 0px; padding: 0px; float: right" dense />
                    </span>
                    <p class="wagon-details">
                      ISO: <b>{{ getCarroIso(carroConvoglio) }}</b> - IMO: <b>{{ getCarroImo(carroConvoglio) }}</b>
                      <br />
                      <v-icon title="Peso Uti / Portata Max Carro">mdi-weight</v-icon> {{ getSumPesoUti(carroConvoglio) }} / {{ getCarroPortataMax(carroConvoglio) }} Kg
                      <br />
                      <v-icon title="Tara Carro">mdi-train-car-centerbeam</v-icon> {{ getCarroTara(carroConvoglio) }} Kg
                      <br />
                      <v-icon title="Lunghezza Carro">mdi-tape-measure</v-icon> {{ getCarroLength(carroConvoglio) }} m
                    </p>
                    <v-icon title="danneggiato" color="error" class="damaged-alert" v-if="carroConvoglio.visitaCarro.danni">mdi-alert-outline</v-icon>
                  </v-card>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                v-if="!tripPlanned && joinConvoyEnabled && selectedConvoy.listCarri.length > 0"
                @click="joinConvoy"
                color="primary"
                :disabled="!selectedConvoyWagonList.length"
              >
              <v-icon class="mr-2">mdi-link</v-icon>Associa carri-convoglio
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>

    <v-row v-if="!viaggioTreno.idConvoglioPianificato && selectionType === 'WAGON_TYPE'">
      <v-col cols="12">
        <v-container fluid  class="ma-0 pa-0">
          <v-row>
            <v-col v-if="selectionType === 'WAGON_TYPE'" cols="6" md="3">
              <v-combobox v-if="!tripPlanned" clearable v-model="selectedWagonType" :items="wagonTypes" label="Tipo carro" item-text="codice" />
            </v-col>
          </v-row>
          <v-row v-if="!tripPlanned && selectedWagonType">
            <v-col cols="4" sm="2">
              <v-text-field type="number" label="numero carri" :rules="[validationRules.greaterThanOrEqualToZero]" v-model="selectedWagonNumber" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn v-if="!tripPlanned && joinWagonTypeEnabled" @click="joinWagonType" color="primary"> <v-icon class="mr-2">mdi-link</v-icon>Associa carri </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import tripVisualizationMixins from "../mixins/tripVisualizationMixins";

export default {
  name: "TripWagonImportPlanning",
  props: {
    tripPlanned: {
      type: Boolean,
      default: false,
    },
    viaggioTreno: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      CARRO_FITTIZIO: "0",
      wagonTypes: [],
      selectionType: "CONVOY_NAME",
      selectedWagonType: null,
      selectedWagonNumber: 0,
      convoyListNames: null,
      selectedConvoyName: null,
      selectedConvoy: null,
      selectedConvoyWagonList: [],
      selectedAllConvoyWagonList: false,
    };
  },
  mixins: [tripVisualizationMixins],
  async mounted() {
    await this.fetchData();
  },
  computed: {
    joinWagonTypeEnabled() {
      return this.selectedWagonType && this.selectedWagonNumber > 0;
    },
    joinConvoyEnabled() {
      return this.selectedConvoy;
    },
  },
  methods: {
    async fetchData() {
      this.wagonTypes = (await this.$api.trainData.getTipiCarro()).data;
      this.getConvoyListNames();
    },
    async changeConvoy() {
      if (!this.selectedConvoyName) {
        this.selectedConvoy = null;
        return;
      }
      this.selectedConvoy = await this.$api.railData.getConvoglioByName(this.selectedConvoyName);
      this.selectedConvoy.listCarri = this.selectedConvoy.listCarri.filter((c) => c.visitaCarro.targa != this.CARRO_FITTIZIO);
    },
    async getConvoyListNames() {
      this.convoyListNames = await this.$api.railData.getNomeConvogliPianificabili();
    },
    async joinWagonType() {
      try {
        await this.$api.trainData.associaTipoCarroViaggio(this.tripId, this.selectedWagonType, this.selectedWagonNumber);
        this.$eventBus.$emit("planningChange");
        this.selectedWagonType = null;
        this.selectedWagonNumber = 0;
      } catch (e) {
        this.showError("aggiornamento muta viaggio non andato a buon fine");
        console.log(e);
      }
    },
    async joinConvoy() {
      try {
        if (!this.selectedConvoyWagonList.length) {
          this.showError("selezionare i carri da associare");
          return;
        }
        const ccIds = this.selectedConvoyWagonList.map((cw) => cw.id);
        var res = await this.$api.trainData.associaCarriViaggioPartenza(this.selectedConvoy, this.viaggioTreno, ccIds);

        if (res.success === false) {
          this.showWarning(res.message);
        }
        this.viaggio = await this.$api.trainData.getViaggioById(+this.tripId);
        this.$eventBus.$emit("planningChange");
        this.selectedConvoy = null;
      } catch (e) {
        this.showError("aggiornamento muta viaggio non andato a buon fine");
        console.log(e);
      }
    },
    async joinEmptyConvoy() {
      try {
        var res = await this.$api.trainData.associaCarriViaggioPartenza(this.selectedConvoy, this.viaggioTreno);

        if (res.success === false) {
          this.showWarning(res.message);
        }
        this.viaggio = await this.$api.trainData.getViaggioById(+this.tripId);
        this.$eventBus.$emit("planningChange");
        this.selectedConvoy = null;
      } catch (e) {
        this.showError("Errore " + e);
        console.log(e);
      }
    },
    async unjoinConvoy() {
      try {       
        await this.$api.trainData.disassociaConvoglio(this.viaggioTreno.idConvoglioPianificato);
        this.selectedConvoyName = null;
        this.$eventBus.$emit("planningChange");
      } catch (e) {
        this.showError("Errore " + e);
        console.log(e);
      }
    },
    selectAllWagons() {
      if (!this.selectedConvoy.listCarri || !this.selectedConvoy.listCarri.length) {
        this.showError("convoglio da associare non selezionato");
        return;
      }
      if (!this.selectedAllConvoyWagonList) {
        this.selectedConvoyWagonList = [];
        return;
      }
      this.selectedConvoyWagonList = this.selectedConvoy.listCarri.filter((cc) => !cc.joined);
    },
  },
  watch: {
    viaggioTreno() {
      this.selectedConvoy = null;
    },
    selectedConvoy() {
      this.selectedAllConvoyWagonList = false;
      this.selectedConvoyWagonList = [];
      if (this.selectedConvoy) {
        this.selectedConvoy.listCarri.forEach((cc) => {
          cc.joined = cc.viaggioOut != null;
        });
      }
    },
  },
};
</script>

<style scoped>
.wagon-group-panel-container {
  padding-top: 5px;
  height: 185px;
  background-color: #ffffff;
  overflow: auto;
}
.wagon-group-panel-container > div:not(.wagon-group-check) {
  display: table-cell;
  height: 30px;
}
.wagon-group-check {
  width: 100%;
  margin-left: 2px;
}
.wagon-group-item {
  cursor: move;
  padding-left: 5px;
  display: table-cell;
}
.wagon-group-item > div {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: 3px;
  width: 150px !important;
  height: 115px;
  overflow: auto;
}
.not-joined-wagon {
  border: 2px solid lightgrey;
}
.joined-wagon {
  border: 2px solid #f9a825;
}
.wagon-header,
.wagon-title {
  font-size: 0.7rem !important;
  font-weight: bold !important;
}
.wagon-details,
.wagon-details i {
  font-size: 0.7rem !important;
}

.wagon-warning {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: center;
}

.damaged-alert {
  position: absolute;
  left: 110px;
  top: 60px;
}
</style>

<template>
  <v-container fill-height fluid>  
    <v-row align="center" justify="center">
      <v-col cols="1">
          <v-btn color="warning" @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
      </v-col>
      <v-col cols="11" class="text-h5 font-weight-regular">
        Pianificazione viaggio in partenza
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-tabs color="warning" v-model="tab">
          <v-tab>{{ getLabelViaggio(viaggio)}}</v-tab>
         <!-- <v-tab v-for="convoglio in convogli" :key="convoglio.id">{{convoglio.nome}}</v-tab>  -->
        </v-tabs>

        <v-tabs-items v-model="tab" touchless>
          <v-tab-item>
            <v-container fill-height fluid>
              <v-row align="center">
                <v-col cols="12">
                  <v-expansion-panels v-model="infoPanel" multiple>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
                        Dati viaggio
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row align="center">
                          <v-col cols="8" md="9" lg="10">
                            <TripInfoPanelFull :viaggio="viaggio" />
                          </v-col>
                          <v-col cols="4" md="3" lg="2">
                            <v-row no-gutters>
                              <v-col cols="6">
                                <TripPlanningButton v-if="!isWorked" :tripId="tripId" :tripPlanned="isPlanned"/>
                              </v-col>
                              <v-col cols="6">
                                <TripPrintDistintaButton v-if="isPlanned" :tripId="tripId" />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>                        
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-expansion-panels v-model="wagonsPanel" multiple>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
                        Gestione carri 
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <TripWagonImportPlanning 
                          v-if="!isPlanned" 
                          :tripId="tripId" 
                          :viaggioTreno="viaggio" 
                          :tripPlanned="isPlanned"
                        />
                        <WagonImportPlanning
                          :tripId="tripId" 
                          :listCarri="viaggio.listCarri"
                          :bookingPianificabiliList="bookingPianificabiliList" 
                          :tripPlanned="isPlanned" 
                        /> 
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
                        Liste di carico
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <ImportUtiList
                        :tripId="tripId"
                        :tripPlanned="isPlanned"
                        />
                      </v-expansion-panel-content>            
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>    
            </v-container>           
          </v-tab-item>
          <!--
          <v-tab-item v-for="convoglio in convogli" :key="convoglio.id">
              {{convoglio}}
          </v-tab-item>
          -->
        </v-tabs-items>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import ImportUtiList from '../../components/rails/trip/ImportUtiList';
import WagonImportPlanning from '../../components/rails/trip/WagonImportPlanning';
import TripWagonImportPlanning from '../../components/rails/trip/TripWagonImportPlanning';
import TripPlanningButton from '../../components/rails/trip/TripPlanningButton';
import TripPrintDistintaButton from '../../components/rails/trip/TripPrintDistintaButton';
import TripInfoPanelFull from '../../components/misc/TripInfoPanelFull';
import tripVisualizationMixins from '../../components/rails/mixins/tripVisualizationMixins';

export default {
  name: "pianificazioneViaggioPartenza",
  components: {
    ImportUtiList,
    WagonImportPlanning,
    TripInfoPanelFull,
    TripWagonImportPlanning,
    TripPlanningButton,
    TripPrintDistintaButton
  },
  mixins: [tripVisualizationMixins],
  data() {
    return {
      tab: null,
      bookings: [],
      bookingList:[],
      infoPanel: [0],
      legendPanel: [],
      wagonsPanel: [0, 1],
      managementPanel: [0],
      convogli: null,
      codiceEsternoSelected:null,
    };
  },
  created(){
    this.$eventBus.$on('planningChange', async () => {
      await this.fetchData();
    });
    this.$eventBus.$on('loadingListReload', async () => {
        await this.fetchData();
      });

    this.$eventBus.$on('railRegistrationReload', async () => {
        await this.fetchData();
    });

      this.$eventBus.$on('handlingInExeReload', async () => {
        await this.fetchData();
    });
      this.$eventBus.$on('utiStateReload', async () => {
        await this.fetchData();
    });

  },
  async mounted(){  
    this.initComponent();  
    await this.fetchData();   
  },
  computed: {
    bookingPianificabiliList(){
      return this._.filter(this.viaggio.listBooking, b => b.viaggioTrenoId === +this.tripId);
    }
  },
  methods: {
    async fetchData() {
      try {
        this.viaggio = await this.$api.trainData.getViaggioById(+this.tripId);
        this.convogli = await this.$api.trainData.findAllConvogliByCarroConvoglioViaggioTreno(this.tripId);
      } catch(e) {
        console.log(e);
      }
    },

  }
};
</script>

<style scoped>
.convoy-vis{
  position: relative;
  overflow-x:scroll;
  cursor: pointer;
}
</style>

<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col cols="12" class="text-center">
        <v-btn 
          v-if="!tripPlanned"
          @click="planningConfirmation"
          color="teal lighten-5"
          x-large
          title="Conferma pianificazione"
        >          
          <v-icon large> mdi-calendar-check </v-icon>
        </v-btn>
        <v-btn 
          v-else
          @click="planningReopening"
          color="success"
          x-large
          title="Riapri pianificazione"
        >          
          <v-icon large> mdi-calendar-check </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import tripVisualizationMixins from '../mixins/tripVisualizationMixins';

export default {  
  name: "TripPlanningButton",
  props: {
    tripPlanned: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  mixins: [tripVisualizationMixins],
  methods: {
    async planningConfirmation(){
      try {
        await this.$api.trainData.confermaPianificazioneViaggio(this.tripId);
        this.$eventBus.$emit('planningChange');
      } catch(e) {
        this.showError('conferma pianificazione non andato a buon fine');
        console.log(e);
      }
    },
    async planningReopening(){
      try {
        await this.$api.trainData.riaperturaPianificazione(this.tripId);
        this.$eventBus.$emit('planningChange');
      } catch(e) {
        this.showError('riapertura pianificazione non andata a buon fine');
        console.log(e);
      }
    }
  },
};
</script>


<template>
  <v-container fluid>
    <v-row class="text-center">
      <v-col cols="12" class="text-center">
        <v-btn 
          @click="stampaDistinta"
          color="primary"
          x-large
          title="Stampa distinta"
        >          
          <v-icon large> mdi-printer </v-icon>
        </v-btn>        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import tripVisualizationMixins from '../mixins/tripVisualizationMixins';

export default {  
  name: "TripPrintDistintaButton",
  props: {
    
  },
  data() {
    return {
    }
  },
  mixins: [tripVisualizationMixins],
  methods: {
    async stampaDistinta(){
      try{
        const _callConfig = {
        responseType: 'blob',
        headers: {
            'Content-Type': "application/octet-stream",
            'Accept': '*/*',
            'Pragma': 'no-cache'
          }
        };
        const response = await this.$api.get(`${this.$apiConfiguration.BASE_PATH}gestioneViaggi/stampaDistintaTreno/${this.tripId}`, _callConfig );
        const fileURL = window.URL.createObjectURL(new Blob([response], { }));
        const fileLink = document.createElement('a');
        fileLink.setAttribute("href", fileURL);
        fileLink.setAttribute('download', 'distintaTreno.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      } catch(e){
        this.showError('generazione distinta non andata a buon fine');
        console.log(e);
      }
    },
  },
};
</script>

